import React, {Component} from 'react';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "underscore";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  FormSelect,
  FormInput,
  Button,
  FormCheckbox,
  FormTextarea,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormRadio
} from "shards-react";
//https://designrevision.com/docs/shards-react/getting-started
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import './style.scss'

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import moment from "moment";

let initSetting = {
  writer: '',
  password: '',
  isAutoWrite: false,
  isShowWriterName:  false,
  isShowProductName: false,
  sentencesNum: '2~3',
  autoReplyStartTime: 9,
  autoReplyEndTime: 18,
  autoReplyFrequency: 3,
  autoRunTimes: [],
  atmosphere: ['', '', ''],
  reviewRatingConfigs: [
    { isAutoWrite: true, rating: '1_20', type: 'na', isShowWriterName:false, isShowProductName: false, tone: ''},
    { isAutoWrite: true, rating: '21_40', type: 'fixed', isShowWriterName:false, isShowProductName: false, tone: '안녕하세요. [구매자명]님. [상품명]리뷰 작성 감사합니다. \n' +
          '[상품별 추가 내용]' +
          '고객님의 소중한 리뷰는 저희에게 큰 힘이 됩니다. 앞으로도 더욱 좋은 상품으로 보답하겠습니다.'},
    { isAutoWrite: true, rating: '41_100', type: 'ai', isShowWriterName:true, isShowProductName: true, tone: '안녕하세요. 고객님. 리뷰 작성 감사합니다. 고객님의 소중한 리뷰는 저희에게 큰 힘이 됩니다. 앞으로도 더욱 좋은 상품으로 보답하겠습니다.'},
  ]
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function isOverThreeMonths(date1, date2) {
  const startDate = moment(date1);
  const endDate = moment(date2);
  const monthsDiff = endDate.diff(startDate, 'months');
  return monthsDiff < 3;
}

function isOverPeriod(date1, date2, periodArr) {
  const startDate = moment(date1);
  const endDate = moment(date2);
  const periodDiff = endDate.diff(startDate, periodArr[1]);
  return periodDiff < periodArr[0];
}

@inject(({ common }) => ({ getStoreTotalConfig: common.getStoreTotalConfig, updateDoc: common.updateDoc, getDoc: common.getDoc }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ company }) => ({ updateStore:company.updateStore, getApplySkins:company.getApplySkins, applyAllSkin:company.applyAllSkin, applySkin:company.applySkin}))
@inject(({ review }) => ({ setReviewSync: review.setReviewSync, getStatusReviewBoard: review.getStatusReviewBoard }))

@observer
class ReviewWriteConfig extends Component {

  //basic
  @observable teamUser = {};
  @observable permissions = {}
  @observable store = {};
  @observable isUse = {};
  @observable company = {};
  @observable isLogin = false;


  //page
  //카테고리 정보와 상품 정보 샵에서 가져와야함.
  @observable setting = initSetting;
  @observable skins = [];
  @observable syncReviewValue = {
    startDate: moment().add(-1, 'days').format('yyyy-MM-DD'),
    endDate: moment().format('yyyy-MM-DD'),
    isOverwrite: false,
  };

  @observable reviewWrightLimit = {
    store_id: null,
    isUseNormalReview: true,
    // isUseSnsReview: false,
    isUseNoPurchase: false,
  }

  @observable basicSettings = {
    store_id: null,
    minChars: 10,
    maxChars: 5000,
    mainPhase: [],
    subPhase: [],
    writeEnabledState: 1,
    writeEnabledDate: 50,
    isDeliveryRating: false
  };

  @observable serarchPeriod = [3, "months", "3개월 단위"];
  @observable newMainPhase = '';
  @observable newSubPhase = '';

  @observable reviewFiltering = {
    store_id: null,
    isRecmFilter: false,
    filterWords: []
  };
  // @observable isRecmFilter = {
  //   isUse: false
  // }
  @observable filterWords = [];
  @observable newFilterWord = { block: '', alter: ''};

  //modals
  @observable isWidgetMode = false;
  @observable isShowCalendar = {
    startDate: false,
    endDate: false
  }
  @observable isShowCalendarForRS = {
    startDate: false,
    endDate: false
  }
  @observable modals = {
    cafeSettingData: {},
    isCafeSettingModal: false
  };

  // @observable reviewAutoReply = {
  //   store_id: null,
  //   isUse: false,
  //   displayTime: 30,
  //   replyContents: []
  // }

  // @observable newReplyContents = {
  //   grade: null,
  //   text: ''
  // };

  constructor(props) {
    super(props);
    this.init()

    // this.onChangeInputs = this.onChangeInputs.bind(this);
    // this.onChangeChecked = this.onChangeChecked.bind(this);
    // this.updateFilter = this.updateFilter.bind(this);
  }


  @action
  init = async (key) => {
    let _this = this;
    if(this.props.isLogin) {
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.teamUser.store;
      this.isUse = false;
      if(this.store.isFreeStore || this.store.isPaidUser) {
        this.isUse = true;
      }
      this.permissions = this.props.me.permissions;


      this.props.getStoreTotalConfig({store_id: this.teamUser.selStore_id}, function(res) {
        console.log(res)
        if(res.errors && res.errors.length > 0) {
          alert(res.errors[0].message);
        }
        else {
          let results;
          if(res) {
            results = res;
            if(results.basicSettings) {
              let getSettings = _.pick(results.basicSettings, 'store_id', 'minChars', 'maxChars', 'mainPhase', 'subPhase', 'writeEnabledState', 'writeEnabledDate', 'isDeliveryRating');
              _this.basicSettings = {..._this.basicSettings , ...getSettings};
            }
            if(results.filtering) {
              _this.reviewFiltering = results.filtering;
              _this.filterWords = results.filtering.filterWords;
            }
            if(results.writeLimit) {
              _this.reviewWrightLimit = results.writeLimit;
            }
            _this.basicSettings.store_id = _this.teamUser.selStore_id;
            _this.reviewFiltering.store_id = _this.teamUser.selStore_id;
            _this.reviewWrightLimit.store_id = _this.teamUser.selStore_id;
          }
        }
      });

      this.getApplySkins(false);

    }
  };

  onChangeInputs = async (event, targetObj) => {
    let { name, value } = event.target;
    let isPrg = true;
    if(targetObj) {
      if(name == 'writeEnabledDate') {
        if(value > 0 && value < 60) {

        }
        else {
          if(value == '' || value === null) {
            value = '';
          }
          else {
            alert('최소 1, 최대 60일까지 가능합니다.')
            isPrg = false;
            console.log(value, typeof value)
          }

        }
      }
      if(isPrg) {
        targetObj[name] = value;
        if(name === "cafe24UseReviewBoardNo") {
          let updateStore = {
            id: this.teamUser.store._id,
            cafe24UseReviewBoardNo: parseInt(value)
          }
          this.props.updateStore( updateStore, function(res) {
            if(res.errors && res.errors.length > 0) {
              toast(res.errors[0].message);
            }
            else {
              toast('업데이트가 완료 되었습니다.');
            }
          });
        }
      }
    }
  };
  nullCheck = async (event, targetObj, defalutValue) => {
    let { name, value } = event.target;
    if(value == '' || value === null) {
      value = defalutValue;
    }
    targetObj[name] = value;
  }

  onChangeChecked = async (event, targetObj, keyArray) => {
    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
      if(keyArray[0] === 'isSetScript') {
        targetObj.id = targetObj._id;
        this.props.applySkin(targetObj, function(res) {
          if(res.errors && res.errors.length > 0) {
            alert(res.errors[0].message);
            targetObj[keyArray[0]] = !targetObj[keyArray[0]];
          }
          else {
            console.log(res)
            toast('처리 되었습니다.');
            //targetObj[keyArray[0]] = !targetObj[keyArray[0]];
          }
        });
      }
      else if(keyArray[0] === 'isRecmFilter') {
        this.props.updateDoc('updateFilter', this.reviewFiltering, function(res) {
          if(res.errors && res.errors.length > 0) {
            toast(res.errors[0].message);
          }
          else {

          }
        });
      }
    }
  };

  @action
  getApplySkins = async (actSync) => {
    let _this = this;
    let updateStore = {
      store_id: this.teamUser.store._id,
      actSync: actSync
    }
    this.props.getApplySkins( updateStore, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        _this.skins = res.data;
        if(actSync) {
          toast('업데이트가 완료 되었습니다.');
        }
      }
    });
  }

  onChangeCheckedforApplySkin = async (event, targetObj, keyArray) => {
    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
      if(keyArray[0] === 'isSetScript') {
        targetObj.id = targetObj._id;
        this.props.applySkin(targetObj, function(res) {
          if(res.errors && res.errors.length > 0) {
            alert(res.errors[0].message);
            targetObj[keyArray[0]] = !targetObj[keyArray[0]];
          }
          else {
            console.log(res)
            toast('처리 되었습니다.');
            //targetObj[keyArray[0]] = !targetObj[keyArray[0]];
          }
        });
      }
    }
  };

  setReviewSync = async (type) => {
    let _this = this;
    //let confimRes = window.confirm("기존 온리플의 리뷰는 모두 삭제되고 카페24 리뷰로 대체 됩니다. 진행하시겠습니까?");
    _this.isLoading = true;
    let data = {
      store_id: _this.store._id,
      getType: type ? type : 'new',

    }
    if(type == 'period') {
      if(isOverPeriod(this.syncReviewValue.startDate, this.syncReviewValue.endDate, this.serarchPeriod)) {
        data.params = this.syncReviewValue;
      }
      else {
        console.log(this.serarchPeriod)
        alert('최대 ' + this.serarchPeriod[3]  + '로 선택해 주세요.');
        return false;
      }
    }


    _this.props.setReviewSync(data, function(res) {
      if(res && res.data) {
        toast(moment(res.data.startDate).format('YYYY-MM-DD') + ' ~ ' + moment(res.data.endDate).format('YYYY-MM-DD') + ' 기간의 리뷰가 ' +  res.data.insertReviewCount + '개 업데이트 되었습니다.');
        if(res.data.insertReviewCount > 0) {
          _this.getReviewList();
        }
      }
      _this.isLoading = false;
    });

  };

  @action
  modalToggle = function(target, data) {
    let _this = this;
    this.modals[target] = !this.modals[target];
    if(!target) {
      for( var i in this.modals) {
        this.modals[i] = false;
      }
    }

    if(target === 'isCafeSettingModal') {
      if(!this.isUse) {
        alert('사용권한이 없습니다.');
        return false;
      }
      if(this.skins.length === 0) {
        this.getApplySkins(true);
      }
    }

    if(data) {
      if(target === 'isCafeSettingModal' && data) {
        this.modals.widgetData.product = data;
      }
      else {
        this.modals.data = data;
      }
    }

    if(target === 'isSyncReview') {

      let data = {
        store_id: this.store._id,
        flatform: 'cafe24'
      }

      this.props.getStatusReviewBoard(data, function(res) {

        if(res && res.data) {
          _this.modals.data = res.data;
          if(res.data.selectPeriod[1] === "years") {
            res.data.selectPeriod[2] = res.data.selectPeriod[0] + "년 단위";
          }
          else if(res.data.selectPeriod[1] === "months") {
            res.data.selectPeriod[2] = res.data.selectPeriod[0] + "월 단위";
          }
          else if(res.data.selectPeriod[1] === "days") {
            res.data.selectPeriod[2] = res.data.selectPeriod[0] + "일 단위";
          }
          _this.serarchPeriod = res.data.selectPeriod;
        }
      });
    }

  };

  setReviewSync = async (type) => {
    let _this = this;
    //let confimRes = window.confirm("기존 온리플의 리뷰는 모두 삭제되고 카페24 리뷰로 대체 됩니다. 진행하시겠습니까?");
    _this.isLoading = true;
    let data = {
      store_id: _this.store._id,
      getType: type ? type : 'new',

    }
    if(type == 'period') {
      // if(isOverThreeMonths(this.syncReviewValue.startDate, this.syncReviewValue.endDate)) {
      //   data.params = this.syncReviewValue;
      // }
      // else {
      //   alert('최대 3개월 단위로 선택해 주세요.');
      //   return false;
      // }
      data.params = this.syncReviewValue;
    }


    _this.props.setReviewSync(data, function(res) {
      if(res && res.data) {
        toast(moment(res.data.startDate).format('YYYY-MM-DD') + ' ~ ' + moment(res.data.endDate).format('YYYY-MM-DD') + ' 기간의 리뷰가 ' +  res.data.insertReviewCount + '개 업데이트 되었습니다.');
        if(res.data.insertReviewCount > 0) {
          _this.getReviewList();
        }
      }
      _this.isLoading = false;
    });

  };

  onChangeSyncReviewValue = async (event, obj) => {
    const { name, value, type } = event.target;
    console.log(name, value, type, obj)
    let newValue;
    if(type == 'checkbox') {
      if(value) {
        newValue = !JSON.parse(value);
      }
      else {
        newValue = true;
      }
    }
    else {
      newValue = value;
    }

    if(obj) {
      obj[name] = newValue;

      if(name == 'all') {
        for(let key in obj) {
          obj[key] = newValue;
        }
      }
      else {
        let isAll = false;
        if(newValue) {
          isAll = true;
          for(let key in obj) {
            if(!obj[key] && key != 'all') {
              isAll = false;
              break;
            }
          }
        }
        obj['all'] = isAll;
      }
    }
    else {
      this.syncReviewValue[name] = newValue;
    }

  };

  onChangeCalendarValueForRS = async (date, dateType) => {
    this.isShowCalendarForRS = {
      startDate: false,
      endDate: false,
    };
    this.syncReviewValue[dateType] = moment(date).format('yyyy-MM-DD');
    // if((this.syncReviewValue.startDate || this.syncReviewValue.endDate) && !isOverPeriod(this.syncReviewValue.startDate, this.syncReviewValue.endDate, this.serarchPeriod)) {
    //   alert('최대 ' + this.serarchPeriod[2]  + '로 선택해 주세요.');
    //
    // }
  }


  updateLimits = async (event) => {
    let _this = this;

    this.props.updateDoc('updateLimits', this.reviewWrightLimit, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        _this.newFilterWord = { block: '', alter: ''};
      }
    });

  };

  addPhrase = async (event, target) => {
    let _this = this;
    if(target == 'mainPhase') {
      this.basicSettings.mainPhase.push(this.newMainPhase);
      this.newMainPhase = '';
    }
    else if(target == 'subPhase') {
      this.basicSettings.subPhase.push(this.newSubPhase);
      this.newSubPhase = '';
    }
    this.props.updateDoc('updateBasicSettings', this.basicSettings, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {

      }
    });
  }

  removePhrase = async (event, target, i) => {
    let _this = this;
    let index = i
    if(target == 'mainPhase') {
      this.basicSettings.mainPhase.splice(i, 1);
    }
    else if(target == 'subPhase') {
      this.basicSettings.subPhase.splice(i, 1);
    }
    this.props.updateDoc('updateBasicSettings', this.basicSettings, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {

      }
    });
  }

  updateBasicSetting = async (event) => {
    console.log(this.basicSettings)
    this.props.updateDoc('updateBasicSettings', this.basicSettings, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast('저장 되었습니다.');
      }
    });
  }

  updateFilter = async (event) => {
    let _this = this;
    if(!_this.newFilterWord.block) {
      alert("차단어를 입력해 주세요");
      return false;
    }
    if(!_this.newFilterWord.alter) {
      alert("대체어를 입력해 주세요");
      return false;
    }
    this.filterWords.push(_this.newFilterWord);
    this.reviewFiltering.filterWords = this.filterWords;

    this.props.updateDoc('updateFilter', this.reviewFiltering, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        _this.newFilterWord = { block: '', alter: ''};
      }

    });
  };

  delFilter = async (del_index, event) => {
    let _this = this;
    this.filterWords.splice(del_index, 1);

    this.reviewFiltering.filterWords = this.filterWords;

    this.props.updateDoc('updateFilter', this.reviewFiltering, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        toast('삭제 되었습니다.');
      }

    });
  };


  onDragStart = async (del_index, event) => {

  };

  onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    this.basicSettings.mainPhase = reorder(
      this.basicSettings.mainPhase,
      result.source.index,
      result.destination.index
    );
  }


  render() {

    // const reorder = (list, startIndex, endIndex) => {
    //   const result = Array.from(list);
    //   const [removed] = result.splice(startIndex, 1);
    //   result.splice(endIndex, 0, removed);
    //
    //   return result;
    // };
    //
    // const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
      userSelect: "none",
      background: isDragging ? "lightgreen" : "",
      ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
      background: isDraggingOver ? "lightblue" : "",
    });

    function skinLanguageCodeKR (code) {
      switch (code) {
        case 'ko_KR':
          return '국문';
        case 'en_US':
          return '영문';
        case 'zh_CN':
          return '중문(간체)';
        case 'zh_TW':
          return '중문(번체)';
        case 'ja_JP':
          return '일문';
        case 'pt_PT':
          return '포르투갈어';
        case 'es_ES':
          return '스페인어';
        case 'vi_VN':
          return '베트남어';
        default:
          return code;

      }
    }

    return (
      <>
      <div className="main-content">
          <PageTitle />

          {( this.isLogin && this.teamUser.emailId ) ?
            <div className="write-post-box">

              {/* 기본 설정 */}
              <div className="section-box" id="basicWriteSettings">
                <div className="substitute-table">
                  <div className="section-tit">
                    <h3>기존 리뷰 연동하기</h3>
                  </div>
                  <Row className="mb-4">
                    <Col md="6" id="reviewWriteIn_item1">
                      <Row>
                        <Col md="6">
                          <Button theme="notice"
                                  onClick={e => this.modalToggle('isSyncReview')}><FeatherIcon
                              icon="plus" size="15" className="nd-icon"/>기존 리뷰 연동하기</Button>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                  <div className="section-tit">
                    <h3>카페 24 연동 설정</h3>
                  </div>
                  <Row className="mb-4">
                    <Col md="6" id="reviewWriteIn_item1">
                      <Row>
                        <Col md="6">
                          <Button theme="notice"
                                  onClick={e => this.modalToggle('isCafeSettingModal')}><FeatherIcon
                              icon="plus" size="15" className="nd-icon"/>설정하기</Button>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                  <div className="section-tit">
                    <h3>기본 설정</h3>
                  </div>
                  <table className="s-table">
                    <colgroup>
                      <col width="170px"/>
                      <col width=""/>
                      <col/>
                    </colgroup>
                    <tbody>
                    <tr>
                      <th>작성 가능 시점 및 기간</th>
                      <td>
                        <FormSelect value={this.basicSettings.writeEnabledState} name="writeEnabledState"
                                    onChange={event => {
                                      this.onChangeInputs(event, this.basicSettings)
                                    }}>
                          <option value="1">배송완료 후</option>
                          <option value="2">주문 완료 후(결제완료)</option>
                          <option value="3">주문 완료 후(결제미완료 포함)</option>
                        </FormSelect>
                      </td>
                      <td>
                        <InputGroup>
                          <FormInput value={this.basicSettings.writeEnabledDate} name="writeEnabledDate"
                                     className="text-center" type="number" max="60"
                                     onChange={event => this.onChangeInputs(event, this.basicSettings)}
                                     onBlur={event => this.nullCheck(event, this.basicSettings, 50)}
                                     placeholder="작성 시점에서 몇 일까지 작성이 가능한지 입력해 주세요."/>
                          <InputGroupAddon type="append" className="addon-text-notice">일 까지 작성 가능(최대
                            60일)</InputGroupAddon>
                        </InputGroup>
                      </td>
                    </tr>
                    <tr>
                      <th>글자수 제한</th>
                      <td>
                        <Row>
                          <Col>
                            <InputGroup>
                              <FormInput value={this.basicSettings.minChars} name="minChars" className="text-center"
                                         onChange={event => this.onChangeInputs(event, this.basicSettings)}
                                         placeholder="최소 입력 글자수를 입력해 주세요."/>
                              <InputGroupAddon type="append" className="addon-text-notice">글자 이상</InputGroupAddon>
                            </InputGroup>
                          </Col>
                          <Col>
                            <InputGroup>
                              <FormInput value={this.basicSettings.maxChars} name="maxChars" className="text-center"
                                         onChange={event => this.onChangeInputs(event, this.basicSettings)}
                                         placeholder="최대 입력 글자수를 입력해 주세요."/>
                              <InputGroupAddon type="append" className="addon-text-notice">글자 이하</InputGroupAddon>
                            </InputGroup>
                          </Col>
                        </Row>
                      </td>
                      <td>
                        <FormCheckbox toggle small checked={this.basicSettings.isDeliveryRating}
                                      onChange={event => this.onChangeChecked(event, this.basicSettings, ['isDeliveryRating'])}>
                          배송 평가 {this.basicSettings.isDeliveryRating ? '사용중' : '미사용중'}
                        </FormCheckbox>
                      </td>
                    </tr>
                    <tr>
                      <th>작성 주요 안내문구</th>
                      <td colSpan={2}>
                        {this.basicSettings && this.basicSettings.mainPhase && this.basicSettings.mainPhase.length > 0 ?
                            <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <ul className='phase-list' ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}>
                                      {this.basicSettings.mainPhase.map((val, i) => (
                                          <Draggable key={i} draggableId={'item-' + i}
                                                     index={i}>{(provided, snapshot) => (
                                              <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                {val}
                                                <FeatherIcon size="15" icon="x-circle" className="nd-icon"
                                                             onClick={e => this.removePhrase(e, 'mainPhase', i)}/>
                                              </li>)}
                                          </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </ul>
                                )}
                              </Droppable>
                            </DragDropContext>
                            :
                            null
                        }
                        <InputGroup>
                          <FormInput value={this.newMainPhase} name="newMainPhase" onChange={event => {
                            this.newMainPhase = event.target.value
                          }} placeholder="문구를 입력해 주세요."/>
                          <InputGroupAddon type="append"><Button theme="secondary"
                                                                 onClick={e => this.addPhrase(e, 'mainPhase')}>추가하기</Button></InputGroupAddon>
                        </InputGroup>
                      </td>
                    </tr>
                    <tr>
                      <th>작성 참고 안내문구</th>
                      <td colSpan={2}>
                        <ul className='phase-list'>
                          {this.basicSettings && this.basicSettings.subPhase && this.basicSettings.subPhase.length > 0 ?
                              this.basicSettings.subPhase.map((val, i) => (
                                  <li className='text-left' key={i} draggable>{val} <FeatherIcon size="15"
                                                                                                 icon="x-circle"
                                                                                                 className="nd-icon"
                                                                                                 onClick={e => this.removePhrase(e, 'subPhase', i)}/>
                                  </li>
                              ))
                              :
                              null
                          }
                        </ul>
                        <InputGroup>
                          <FormInput value={this.newSubPhase} name="newSubPhase" onChange={event => {
                            this.newSubPhase = event.target.value
                          }} placeholder="문구를 입력해 주세요."/>
                          <InputGroupAddon type="append"><Button theme="secondary"
                                                                 onClick={e => this.addPhrase(e, 'subPhase')}>추가하기</Button></InputGroupAddon>
                        </InputGroup>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div className="btn-save">
                    <Button className="save-button" onClick={event => this.updateBasicSetting(event)}>저장</Button>
                  </div>
                </div>
              </div>

              {/*필터링*/}
              <div className="section-box">
                <div className="substitute-table">
                  <div className="section-tit">
                    <h3>리뷰 단어 필터링 설정</h3>
                  </div>
                  <div className="auto-reply-use">
                    <div className="table-tit">
                      <h4>추천 필터링 사용 여부</h4>
                    </div>
                    <table className="s-table">
                      <colgroup>
                        <col width="245px"/>
                        <col width=""/>
                      </colgroup>
                      <tbody>
                      <tr>
                      <th>
                          <fieldset>
                            <FormCheckbox toggle small checked={this.reviewFiltering.isRecmFilter} onChange={event=> this.onChangeChecked(event, this.reviewFiltering, ['isRecmFilter'])} >
                              추천 필터링 {this.reviewFiltering.isRecmFilter ? '사용중' : '미사용중'}
                            </FormCheckbox>
                          </fieldset>
                        </th>
                        <td>
                          온리뷰가 제공하는 추천 필터링을 사용하시고 필터링을 아래에 더 추가가 가능합니다.
                        </td>
                        {/*<td>*/}
                        {/*  자동 답글 사용 시, 일반리뷰(SNS 리뷰 외)에 아래 내용으로 사용자 평가에 따라 랜덤하게 답글이 작성됩니다.*/}
                        {/*</td>*/}
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <table className="s-table mt-3">
                    <colgroup>
                      <col width="20px" />
                      <col width="" />
                      <col width="" />
                      <col width="200px" />
                    </colgroup>
                    <thead>
                    <tr>
                      <th>no</th>
                      <th>차단어</th>
                      <th>대체어</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.filterWords && this.filterWords.length > 0 ?
                        this.filterWords.map((item, i) => (
                            <tr key={i}>
                              <td className="keyword-num">{i+1}</td>
                              <td>{item.block}</td>
                              <td>{item.alter}</td>
                              <td><Button theme="warning" onClick={e => this.delFilter(i, e)}>삭제하기</Button></td>
                            </tr>
                        ))
                        :
                        null
                    }
                    <tr>
                      <td>+</td>
                      <td><FormGroup><FormInput value={this.newFilterWord.block} name="block" className="text-center" onChange={event => this.onChangeInputs(event, this.newFilterWord)} placeholder="차단어를 입력해 주세요." /></FormGroup></td>
                      <td><FormGroup><FormInput value={this.newFilterWord.alter} name="alter" className="text-center" onChange={event => this.onChangeInputs(event, this.newFilterWord)} placeholder="대체어를 입력해 주세요." /></FormGroup></td>
                      <td><Button theme="primary" onClick={e => this.updateFilter(e)}>추가하기</Button></td>
                    </tr>
                    </tbody>
                  </table>
                  {/*<div className="btn-save">*/}
                  {/*  <Button className="save-button" onClick={event => this.updateReplyContent(event)}>저장</Button>*/}
                  {/*</div>*/}
                </div>
              </div>

              <Modal size="lg" className='modal-review-write-form' open={this.modals.isCafeSettingModal} toggle={this.modalToggle.bind(this)} centered={true}>
                <ModalHeader>카페 24 디자인 스킨 및 게시판 설정</ModalHeader>
                <ModalBody className="history-window-popup">
                  {/*<p><span className='warning-font'>* 해당 리뷰작성에 대한 책임은 작성자 또는 작성자가 속한 회사에 책임이 있습니다.</span></p>*/}
                  <div className="history-popup-contents">
                    <div className="popup-contents-main">
                      <h4>상품정보</h4>
                      <table className="s-table">
                        <colgroup>
                          <col width="170px"/>
                          <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                          <th>디자인 스킨 적용</th>
                          <td>
                            <Row>
                              <Col md="12">
                                <ul className="skin-list">
                                  {this.skins && this.skins.length > 0 ?
                                      this.skins.map((item, j) => (
                                          <li key={j}>
                                            <FormCheckbox toggle small checked={item.isSetScript}
                                                          onChange={event => this.onChangeCheckedforApplySkin(event, item, ['isSetScript'])}>
                                              [{item.usage_type === 'S' || item.usage_type === 'C' || item.usage_type === 'I' ? "PC" : ""}{item.usage_type === 'M' || item.usage_type === 'N' ? "모바일" : ""}]{item.skin_name}/{skinLanguageCodeKR(item.language_code)}<strong
                                                className="warning-font">{item.published_in == '1' ? (' - ' + (item.usage_type == 'M' ? '모바일' : 'PC') + ' 사용스킨') : ''}</strong>
                                            </FormCheckbox>
                                          </li>
                                      ))
                                      : null
                                  }
                                </ul>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-right">
                                <Button className="save-button" onClick={event => this.getApplySkins(true)}>스킨 리스트
                                  업데이트</Button>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <th>리뷰 게시판 ID</th>
                          <td>
                            <InputGroup><FormInput value={this.store.cafe24UseReviewBoardNo} id="cafe24UseReviewBoardNo"
                                                   name="cafe24UseReviewBoardNo" className="text-center"
                                                   onChange={event => this.onChangeInputs(event, this.store)}
                                                   type="number"
                                                   placeholder="초기 셋팅 id는 4입니다."/></InputGroup>
                          </td>
                        </tr>
                        {/*<tr>
                          <th>카페24 리뷰 가져오기</th>
                          {this.reviewCount && this.reviewCount.review === 0 && this.reviewCount.reviewScore === 0 ? (
                              <td className="text-left"><Button id="getPlatformReview" size="sm"
                                                                onClick={event => this.setReviewSync('import')}>카페24 리뷰
                                가져오기</Button>(* 온리뷰에서 작성된 모든 리뷰데이터가 삭제되니 주의부탁 드립니다.)</td>
                          ) : <td className="text-left"><span className="text-primary">카페24 리뷰는 처음 시작할때 또는 기존 리뷰데이터를 초기화 후 사용이 가능합니다.</span><Button
                              className="ml-2" size="sm" onClick={event => this.initReviewData()}>리뷰데이터 초기화</Button></td>}
                        </tr>
                        {this.reviewCount && this.reviewCount.review === 0 && this.reviewCount.reviewScore === 0 ? (
                            <tr>
                              <th>카페24 리뷰 내보내기 해당 기능은 슈퍼관리자로 이동</th>
                              <td className="text-left"><Button size="sm" onClick={event => this.setReviewSync('export')}>카페24
                                리뷰 내보내기</Button>(* 온리뷰에서 작성된 모든 리뷰가 카페24 리뷰 게시판으로 데이터를 전달됩니다.)
                              </td>
                            </tr>
                        ) : null}*/}
                        {/*{this.teamUser.emailId == "admin@onnomads.com" ? (*/}
                        {/*    <tr>*/}
                        {/*      <th>리뷰데이터 리셋</th>*/}

                        {/*    </tr>*/}
                        {/*) : null}*/}
                        </tbody>
                      </table>
                    </div>


                    <div className="text-center">
                      {/*<Button onClick={event => this.updateReview(this.modals.widgetData)}>리뷰작성</Button>*/}
                      <Button className="ml-2" theme="light"
                              onClick={event => this.modals.isCafeSettingModal = false}>닫기</Button>
                    </div>
                  </div>

                </ModalBody>
              </Modal>

              <Modal size="lg" className='modal-review-synk-form' open={this.modals.isSyncReview}
                     toggle={this.modalToggle.bind(this)} centered={true}>
                <ModalHeader>리뷰 가져오기</ModalHeader>
                <ModalBody className="history-window-popup">
                  {/*<p><span className='warning-font'>* 해당 리뷰작성에 대한 책임은 작성자 또는 작성자가 속한 회사에 책임이 있습니다.</span></p>*/}
                  <div className="history-popup-contents">
                    <div className="popup-contents-main">

                      <h4 className="mt-2">작성시간 ({'최대 ' + this.serarchPeriod[2]  + '로 선택해 주세요.'})</h4>
                      <Row>
                        <Col md="5">
                          <div className="calendar-box">
                            <div className="calendar-wrap">
                              <FormInput disabled={this.isWidgetMode} name="startDate" placeholder="" onChange={event => {
                                this.onChangeSyncReviewValue(event)
                              }} value={this.syncReviewValue.startDate} onClick={() => {
                                this.isShowCalendarForRS.endDate = false;
                                this.isShowCalendarForRS.startDate = !this.isShowCalendarForRS.startDate;
                              }} className="table-input-text search-calendar star"/>
                              {this.isShowCalendarForRS.startDate ? (
                                  <Calendar value={moment(this.syncReviewValue.startDate).toDate()} className="startDate"
                                            onChange={date => {
                                              this.onChangeCalendarValueForRS(date, 'startDate')
                                            }} maxDate={new Date()}/>) : null}
                            </div>
                          </div>
                        </Col>
                        <Col md="1" className="text-center bt-symbol">&#126;</Col>
                        <Col md="5">
                          <div className="calendar-wrap">
                            <FormInput disabled={this.isWidgetMode} name="endDate" placeholder="" onChange={event => {
                              this.onChangeSyncReviewValue(event)
                            }} value={this.syncReviewValue.endDate} onClick={() => {
                              this.isShowCalendarForRS.startDate = false;
                              this.isShowCalendarForRS.endDate = !this.isShowCalendarForRS.endDate;
                            }} className="search-calendar end"/>
                            {this.isShowCalendarForRS.endDate ? (
                                <Calendar value={moment(this.syncReviewValue.startDate).toDate()} className="endDate" onChange={date => {
                                  this.onChangeCalendarValueForRS(date, 'endDate')
                                }} maxDate={new Date()}/>) : null}
                          </div>
                        </Col>
                      </Row>

                      <h4 className="mt-2">추가 방법</h4>
                      <Row>
                        <Col md="12">
                          <FormCheckbox toggle name="isOverwrite" checked={this.syncReviewValue.isOverwrite}
                                        onChange={event => {
                                          this.syncReviewValue.isOverwrite = !this.syncReviewValue.isOverwrite
                                        }} value={this.syncReviewValue.isOverwrite}>
                            {this.syncReviewValue.isOverwrite ? '새로운 리뷰는 추가하며, 기존 리뷰는 수정, 삭제하신 변경사항을 업데이트합니다.' : '새로운 리뷰는 추가하며, 기존 리뷰는 그대로 유지합니다.'}
                          </FormCheckbox>
                        </Col>
                      </Row>

                    </div>
                    <div className="text-center">
                      <Button onClick={event => this.setReviewSync('period')}>리뷰가져오기</Button>
                      <Button className="ml-2" theme="light" onClick={event => this.modals.isSyncReview = false}>닫기</Button>
                    </div>
                  </div>

                </ModalBody>
              </Modal>


            </div>
            :
            null
          }
        </div>


      </>
    )
  }
}

export default ReviewWriteConfig;
